


























import {
  Component, Vue, Provide, Ref, Prop,
} from 'vue-property-decorator';
import { TitleInterface } from '@/config';

@Component

export default class PieTitle extends Vue {
    @Prop() id!: string;

    @Prop() title!: string;

    @Provide() editing = false;

    @Provide() value = '';

    @Ref('title') readonly refTitle!: HTMLInputElement;

    startEditing() {
      this.value = this.title;
      this.editing = true;
      this.$nextTick(() => this.refTitle.focus());
    }

    stopEditing() {
      if (this.editing
          && this.value && this.value !== this.title) {
        const payload: TitleInterface = {
          id: this.id,
          title: this.value,
        };

        this.$store.dispatch('savePieTitle', payload)
          .then(() => this.$bvToast.toast('Successfully updated title.', {
            variant: 'success',
            noCloseButton: true,
          }));
      }

      this.editing = false;
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }
}
