export const Status = {
  OFF: 0,
  ON: 1,
  SOLD_OUT: 2,
  HIDDEN: 3,
  FEATURED: 4,
};

export interface PieInterface {
  docid?: string;
  id?: string;
  title: string;
  price: number;
  group: number;
  order: number;
  status: number;
  timer?: number;
  deadline?: number;
}

export interface PieGroupInterface {
  docid?: string;
  id?: number;
  title: string;
  order: number;
  status: number;
  count: number;
  column: number | null;
}

export interface PieStatus {
  id: string;
  status: number;
  group: number;
}

export interface TitleInterface {
  id: string;
  title: string;
}

export interface PieTimer {
  id: string;
  timer: number;
  deadline?: number;
}

export interface ColumnInterface {
  id: number;
  groups: number;
  count: number;
  size: number;
}

export default {};
