












































































import {
  Component, Vue, Prop, Provide,
} from 'vue-property-decorator';
import Pie from '@/components/Pie.vue';
import PieGroup from '@/components/PieGroup.vue';
import Filters from '@/filters';
import { PieInterface, Status } from '@/config';

import '@/assets/scss/app.scss';

import {
  BVConfigPlugin,
  LayoutPlugin,
  AlertPlugin,
  BootstrapVue,
  BIcon,
  BIconTrash,
} from 'bootstrap-vue';

Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(AlertPlugin);

Vue.use(BVConfigPlugin, {
  breakpoints: ['xs', 'sm', 'lg', 'xl', 'xxl'],
  BAlert: { variant: 'danger' },
  BButton: { variant: 'primary' },
  BCardTitle: {
    titleTag: 'h2',
  },
  BCardSubTitle: {
    subTitleTag: 'h4',
    subTitleTextVariant: 'primary',
  },
});

Vue.component('BIcon', BIcon);
Vue.component('BIconTrash', BIconTrash);

@Component({
  components: {
    Pie,
    PieGroup,
  },
  filters: {
    ...Filters,
  },
})

export default class GroupList extends Vue {
    @Prop({ default: false }) private auth!: boolean;

    @Provide() status = Status;

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }

    hasSubtitle(title: string): boolean {
      return !!title.includes('(') && !!this.status;
    }

    maintitle(title: string): string {
      return this.hasSubtitle(title)
        ? title.substr(0, title.indexOf('(')) : '';
    }

    subtitle(title: string): string {
      return this.hasSubtitle(title)
        ? title.substr(title.indexOf('('), title.length) : '';
    }

    isOff(status: boolean): boolean {
      return this.isAdmin && !status;
    }

    isVisible(status: boolean): boolean {
      return this.isAdmin || status;
    }

    get groups() {
      return [
        this.$store.getters.getActiveGroup(0),
        this.$store.getters.getActiveGroup(1),
        this.$store.getters.getActiveGroup(2),
      ];
    }

    getPies(groupId: number) {
      return this.$store.state.pies.filter((pie: PieInterface) => pie.group === groupId
        && pie.status !== Status.OFF);
    }
}
