import Vue from 'vue';
import Vuex from 'vuex';
import { db } from './firebase';
import {
  Status, PieInterface, PieGroupInterface, PieStatus, PieTimer,
  TitleInterface, ColumnInterface,
} from './config';

Vue.use(Vuex);

const defaultPie: PieInterface = {
  title: 'New Pie',
  price: 5,
  status: Status.ON,
  group: 0,
  order: 999,
};

const defaultPieGroup: PieGroupInterface = {
  id: 0,
  title: 'New Menu',
  status: Status.ON,
  order: 999,
  count: 0,
  column: null,
};

export default new Vuex.Store({
  state: {
    pies: Array<PieInterface>(),
    groups: Array<PieGroupInterface>(),
    columns: [
      {
        id: 0, groups: 0, count: 0, size: 0,
      },
      {
        id: 1, groups: 0, count: 0, size: 0,
      },
      {
        id: 2, groups: 0, count: 0, size: 0,
      },
    ] as Array<ColumnInterface>,
  },

  getters: {
    getActiveGroups:
      (state) => () => state.groups.filter((group) => group.status !== Status.OFF),
    getActiveGroup:
      (state) => (column: number) => state.groups.filter((group) => group.status
        !== Status.OFF && (group.column !== null && group.column % 3 === column)),
    getPiesByGroupId:
      (state) => (id: number) => state.pies.filter((pie) => pie.group === id),

    isIOS: () => navigator.userAgent.includes('Mac') && 'ontouchend' in document,
  },

  mutations: {
    addGroup(state, group: PieGroupInterface) {
      if (group.column !== null) {
        const foundColumn = state.columns.find((col) => col.id === group.column) as ColumnInterface;

        foundColumn.groups += 1;
        foundColumn.count += group.count;
        foundColumn.size = (foundColumn.groups * 3) + foundColumn.count;

        return state.groups.push({ ...defaultPieGroup, ...group });
      }

      const colSize = Math.min(...state.columns.map((col) => col.size));
      const foundColumn = state.columns.find((col) => col.size === colSize) as ColumnInterface;

      foundColumn.groups += 1;
      foundColumn.count += group.count;
      foundColumn.size = (foundColumn.groups * 3) + foundColumn.count;

      const addColumn = {
        column: group.column !== null ? group.column : foundColumn.id,
      };

      return state.groups.push({ ...defaultPieGroup, ...group, ...addColumn });
    },

    updateGroup(state, group: PieGroupInterface) {
      const index = state.groups.findIndex((existing) => existing.docid === group.docid);

      state.groups[index].title = group.title;
      state.groups[index].status = group.status;
      state.groups[index].order = group.order;
    },

    removeGroup(state, group: PieGroupInterface): void {
      state.groups.splice(state.groups.findIndex((existing) => existing.docid
        === group.docid), 1);
    },

    addPie(state, pie: PieInterface) {
      state.pies.push(pie);
    },

    updateGroupCount(state, id: number) {
      const group = state.groups.find((found: PieGroupInterface) => found.id
        === id) as PieGroupInterface;

      group.count = state.pies.filter((pie: PieInterface) => pie.group === id
        && pie.status !== Status.OFF).length;
    },

    saveGroupCount(state, id: number) {
      const group = state.groups.find((found: PieGroupInterface) => found.id
        === id) as PieGroupInterface;

      group.count = state.pies.filter((pie: PieInterface) => pie.group === id).length;

      db.collection('groups')
        .doc(group.docid)
        .update({ count: group.count });
    },

    updatePie(state, pie: PieInterface) {
      const index = state.pies.findIndex((existing) => existing.docid === pie.docid);

      state.pies[index].title = pie.title;
      state.pies[index].status = pie.status;
      state.pies[index].price = pie.price;
      state.pies[index].timer = pie.timer;
      state.pies[index].deadline = pie.deadline;
      state.pies[index].order = pie.order;
    },

    removePie(state, pie: PieInterface): void {
      state.pies.splice(state.pies.findIndex((existing) => existing.docid === pie.docid), 1);
    },

    columns(state, total: number) {
      for (let i = 0; i <= total; i += 1) {
        state.columns.push({
          id: i,
          count: i,
        } as ColumnInterface);
      }
    },

  },

  actions: {
    fetchGroups(context) {
      const collection = db.collection('groups').orderBy('order');

      collection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => context.dispatch('onGroupChanges', change));
      }, (err) => {
        console.log(`Encountered error: ${err}`);
      });
    },

    onGroupChanges({ commit }, change: firebase.firestore.DocumentChange) {
      const data = change.doc.data();

      const group: PieGroupInterface = {
        docid: change.doc.id,
        id: data.id,
        title: data.title,
        order: data.order,
        status: data.status,
        count: data.count || 0,
        column: data.column || null,
      };

      if (change.type === 'added') {
        commit('addGroup', group);
      } else if (change.type === 'modified') {
        commit('updateGroup', group);
      } else if (change.type === 'removed') {
        commit('removeGroup', group);
      }
    },

    addGroup({ commit }, group: PieGroupInterface) {
      commit('addGroup', group);
    },

    updateGroup({ commit }, group: PieGroupInterface) {
      commit('updateGroup', group);
    },

    removeGroup({ commit }, group: PieGroupInterface) {
      commit('removeGroup', group);
    },

    fetchPies({ dispatch }) {
      const collection = db.collection('pies').orderBy('order');

      collection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => dispatch('onPieChanges', change));
      }, (err) => {
        console.log(`Encountered error: ${err}`);
      });
    },

    onPieChanges({ commit }, change: firebase.firestore.DocumentChange) {
      const data = change.doc.data();

      const pie: PieInterface = {
        docid: change.doc.id,
        title: data.title,
        price: data.price,
        status: data.status,
        group: data.group,
        order: data.order,
        timer: data.timer,
        deadline: data.deadline,
      };

      if (change.type === 'added') {
        commit('addPie', pie);
      } else if (change.type === 'modified') {
        commit('updatePie', pie);
      } else if (change.type === 'removed') {
        commit('removePie', pie);
      }
    },

    addPie(context, pie: PieInterface) {
      db.collection('pies')
        .add(pie)
        .then(() => context.commit('saveGroupCount', pie.group));
    },

    savePie(context, pie: PieInterface) {
      db.collection('pies')
        .doc(pie.docid)
        .update(pie);
    },

    savePieStatus(context, payload: PieStatus) {
      db.collection('pies')
        .doc(payload.id)
        .update({ status: payload.status, timer: null, deadline: null })
        .then(() => context.commit('updateGroupCount', payload.group));
    },

    savePieTitle(context, payload: TitleInterface) {
      db.collection('pies')
        .doc(payload.id)
        .update({ title: payload.title });
    },

    startPieTimer(context, payload: PieTimer) {
      db.collection('pies')
        .doc(payload.id)
        .update({
          timer: payload.timer,
          deadline: payload.deadline,
        });
    },

    updatePieTimer(context, payload: PieTimer) {
      db.collection('pies')
        .doc(payload.id)
        .update({
          timer: payload.timer ? payload.timer : null,
        });
    },

    stopPieTimer(context, id: string) {
      db.collection('pies')
        .doc(id)
        .update({
          timer: null,
          deadline: null,
        });
    },

    saveGroupTitle(context, payload: TitleInterface) {
      db.collection('groups')
        .doc(payload.id)
        .update({ title: payload.title });
    },

    saveGroupStatus(context, payload: PieStatus) {
      db.collection('groups')
        .doc(payload.id)
        .update({ status: payload.status });
    },

    completedPieTimer(context, id: string) {
      db.collection('pies')
        .doc(id)
        .update({
          status: Status.ON,
          timer: null,
          deadline: null,
        });
    },

    createPie({ dispatch }, attributes) {
      dispatch('addPie', { ...defaultPie, ...attributes });
    },

    deletePie(context, pie: PieInterface) {
      db.collection('pies')
        .doc(pie.docid)
        .delete()
        .then(() => context.commit('saveGroupCount', pie.group));
    },

  },
});
