





























import {
  Component, Vue, Prop, Provide,
} from 'vue-property-decorator';
import { Status, PieGroupInterface, PieInterface } from '@/config';
import Pie from './Pie.vue';
import PieGroupTitle from './PieGroupTitle.vue';
import PieGroupStatus from './PieGroupStatus.vue';

@Component({
  components: {
    Pie,
    PieGroupTitle,
    PieGroupStatus,
  },
})
export default class PieGroup extends Vue {
    @Prop() private auth!: boolean

    @Prop() private group!: PieGroupInterface

    @Provide() status = Status;

    @Provide() editing = false;

    get pies() {
      return this.$store.getters.getPiesByGroupId(this.group.id);
    }

    showPie(pie: PieInterface): boolean {
      return this.auth || (pie.status !== Status.OFF);
    }

    createPie() {
      this.$store.dispatch('createPie', {
        group: this.group.id as number,
      });
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }

    get isFeatured(): boolean {
      return this.group.status === this.status.FEATURED;
    }

    get isOff(): boolean {
      return this.group.status === this.status.OFF;
    }
}
