



































import {
  Component, Vue, Provide, Ref, Prop,
} from 'vue-property-decorator';
import { TitleInterface } from '@/config';

@Component

export default class PieGroupTitle extends Vue {
    @Prop() id!: string;

    @Prop() title!: string;

    @Provide() editing = false;

    @Provide() value = '';

    @Ref('title') readonly refTitle!: HTMLInputElement;

    get maintitle() {
      return this.title.includes('(') ? this.title.substr(0, this.title.indexOf('(')) : '';
    }

    get subtitle() {
      return this.title.includes('(') ? this.title.substr(this.title.indexOf('('), this.title.length) : '';
    }

    startEditing() {
      this.value = this.title;
      this.editing = true;
      this.$nextTick(() => this.refTitle.focus());
    }

    stopEditing() {
      if (this.editing && this.value
          && this.value !== this.title) {
        const payload: TitleInterface = {
          id: this.id,
          title: this.value,
        };

        this.$store.dispatch('saveGroupTitle', payload)
          .then(() => this.$bvToast.toast('Successfully updated title.', {
            variant: 'success',
            noCloseButton: true,
          }));
      }

      this.editing = false;
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }
}
