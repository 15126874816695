

























































import {
  Component, Vue, Provide, Ref, Prop, Watch,
} from 'vue-property-decorator';
import { PieInterface } from '../config';

@Component

export default class PieTimer extends Vue {
    @Prop() pie!: PieInterface;

    @Prop() timer!: number;

    @Provide() editing = false;

    @Provide() value = 18;

    @Ref('timer') readonly refTimer!: HTMLInputElement;

    created() {
      this.updateTimerValue();
    }

    startTimer(): void {
      const deadline = new Date().getTime() + (+this.value * 60000);
      const payload = {
        id: this.pie.docid,
        timer: +this.value,
        deadline,
      };

      this.$store.dispatch('startPieTimer', payload)
        .then(() => {
          this.$bvToast.toast(`Successfully set ${this.pie.title} timer at ${this.value} minute(s).`, {
            variant: 'success',
            noCloseButton: true,
          });
        });

      this.editing = false;
    }

    stopTimer(): void {
      this.$store.dispatch('stopPieTimer', this.pie.docid)
        .then(() => {
          this.$bvToast.toast(`Successfully stopped ${this.pie.title} timer.`, {
            variant: 'success',
            noCloseButton: true,
          });
        });

      this.editing = false;
      this.value = 18;
    }

    startEditing(): void {
      this.editing = true;
      this.$nextTick(() => this.refTimer.focus());
    }

    stopEditing(): void {
      this.editing = false;
    }

    get hasTimer(): boolean {
      return !!this.pie?.timer;
    }

    get humanMinutes(): string {
      if (!this.pie?.timer || this.pie.timer === 1) {
        return '1 Minute';
      }

      return `${this.pie.timer} Minutes`;
    }

    @Watch('pie.timer', { immediate: true })
    onTimerChanged(value: number, old: number) {
      if (!old && !!value) {
        this.runTimer();
      }
    }

    checkDeadline() {
      if (this.pie?.deadline) {
        const now = new Date().getTime();
        const deadline = this.pie.deadline - now;
        const minutesLeft = Math.ceil(deadline / 60000);

        // If minutes have changes, push time to database
        if (minutesLeft && minutesLeft !== this.pie?.timer) {
          this.$store.dispatch('updatePieTimer', {
            id: this.pie.docid,
            timer: minutesLeft,
          });
        }

        // Update minutes left
        if (!this.editing) {
          this.value = minutesLeft;
        }

        // Deadline reached
        if (deadline < 1 || minutesLeft < 1) {
          this.resetTimer();
        }

        console.log('checking deadline', deadline, minutesLeft);
      }
    }

    runTimer(): void {
      if (this.pie?.timer) {
        this.checkDeadline();

        setTimeout(() => this.runTimer(), 1000);
      }
    }

    resetTimer(): void {
      this.$store.dispatch('completedPieTimer', this.pie.docid)
        .then(() => {
          this.$bvToast.toast(`${this.pie.title} is now available.`, {
            variant: 'success',
            noCloseButton: true,
          });
        });

      this.value = 18;
    }

    updateTimerValue(): void {
      if (this.pie?.timer) {
        this.value = +this.pie.timer;
      }
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }
}
