







































import {
  Component, Vue, Provide, Ref, Prop, Watch,
} from 'vue-property-decorator';
import { Status, PieInterface } from '../config';

@Component

export default class PieStatus extends Vue {
    @Prop() pie!: PieInterface;

    @Provide() status = Status;

    @Provide() value = 1;

    @Ref('status') readonly refStatus!: HTMLInputElement;

    @Watch('pie.status')
    onStatusChange() {
      this.value = this.pie.status;
    }

    @Watch('value')
    onChange() {
      if (this.hasUpdatedStatus) {
        this.saveNewStatus();
      }
    }

    created() {
      this.updateStatusValue();
    }

    saveNewStatus(): void {
      const payload = {
        id: this.pie?.docid,
        status: +this.value,
        group: this.pie?.group,
      };

      const { humanStatus } = this;
      const title = this.pie?.title;

      this.$store.dispatch('savePieStatus', payload)
        .then(() => this.$bvToast.toast(`Successfully changed ${title} to '${humanStatus}'.`, {
          variant: 'success',
          noCloseButton: true,
        }));
    }

    updateStatusValue(): void {
      this.value = this.pie?.status;
    }

    get hasUpdatedStatus(): boolean {
      return this.value !== this.pie?.status;
    }

    get humanStatus(): string {
      if (+this.value === this.status.ON) {
        return 'On';
      }
      if (+this.value === this.status.OFF) {
        return 'Off';
      }
      if (+this.value === this.status.SOLD_OUT) {
        return 'Sold Out';
      }

      return `Status #${this.value}`;
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }

    onDelete() {
      this.$bvModal.msgBoxConfirm(`Are you sure you wish to delete ${this.pie.title}?`)
        .then((confirmed) => confirmed && this.$store.dispatch('deletePie', this.pie));
    }
}
