




























import {
  Component, Vue, Provide, Ref, Prop,
} from 'vue-property-decorator';
import Filters from '@/filters';
import { db } from '../firebase';

@Component({
  filters: {
    ...Filters,
  },
})

export default class PiePrice extends Vue {
    @Prop() id!: string;

    @Prop() price!: number;

    @Provide() editing = false;

    @Provide() value!: number;

    @Ref('price') readonly refPrice!: HTMLInputElement;

    startEditing() {
      this.value = this.price;
      this.editing = true;
      this.$nextTick(() => this.refPrice.focus());
    }

    stopEditing() {
      const price: number = +this.value;

      if (this.editing && price !== this.price) {
        db.collection('pies')
          .doc(this.id)
          .update({ price })
          .then(() => {
            this.$bvToast.toast('Successfully updated price.', {
              variant: 'success',
              noCloseButton: true,
            });
          });
      }

      this.editing = false;
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }
}
