































import {
  Component, Vue, Provide, Ref, Prop, Watch,
} from 'vue-property-decorator';
import { Status, PieGroupInterface } from '@/config';

@Component

export default class PieGroupStatus extends Vue {
    @Prop() group!: PieGroupInterface;

    @Provide() status = Status;

    @Provide() value = 1;

    @Ref('status') readonly refStatus!: HTMLInputElement;

    @Watch('group.status')
    onStatusChange() {
      this.value = this.group.status;
    }

    @Watch('value')
    onChange() {
      if (this.hasUpdatedStatus) {
        this.saveNewStatus();
      }
    }

    created() {
      this.updateStatusValue();

      if (!this.value) {
        this.value = 1;
      }
    }

    saveNewStatus(): void {
      const payload = {
        id: this.group?.docid,
        status: +this.value,
      };

      const { humanStatus } = this;
      const title = this.group?.title;

      this.$store.dispatch('saveGroupStatus', payload)
        .then(() => this.$bvToast.toast(`Successfully changed ${title} to '${humanStatus}'.`, {
          variant: 'success',
          noCloseButton: true,
        }));
    }

    updateStatusValue(): void {
      this.value = this.group?.status;
    }

    get hasUpdatedStatus(): boolean {
      return this.value !== this.group?.status;
    }

    get humanStatus(): string {
      if (+this.value === this.status.ON) {
        return 'On';
      }
      if (+this.value === this.status.OFF) {
        return 'Off';
      }
      if (+this.value === this.status.FEATURED) {
        return 'Featured';
      }

      return `Status #${this.value}`;
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }
}
